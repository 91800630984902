import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprises-voice-solution',
  templateUrl: './enterprises-voice-solution.component.html',
  styleUrls: ['./enterprises-voice-solution.component.css']
})
export class EnterprisesVoiceSolutionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
