import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SiteComponent } from './site/site.component';
import { EnterprisesComponent } from './enterprises/enterprises.component';
import { FuturehomeComponent } from './futurehome/futurehome.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TechnologyComponent } from './technology/technology.component';
import { ClientsComponent } from './clients/clients.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { WhyUsComponent } from './why-us/why-us.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CareersComponent } from './careers/careers.component';

import { HomeComponent } from './enterprises/home/home.component';
import { BusinessSolutionsComponent } from './enterprises/business-solutions/business-solutions.component';
import { InternetLeaseLineServicesComponent } from './enterprises/internet-lease-line-services/internet-lease-line-services.component';
import { BusinessBroadbandComponent } from './enterprises/business-broadband/business-broadband.component';
import { WifiSolutionsComponent } from './enterprises/wifi-solutions/wifi-solutions.component';
import { CustomizeSolutionsComponent } from './enterprises/customize-solutions/customize-solutions.component';
import { CorporateEventsComponent } from './enterprises/corporate-events/corporate-events.component';
import { PlansComponent } from './enterprises/plans/plans.component';
import { EnterprisesVoiceSolutionComponent } from './enterprises/enterprises-voice-solution/enterprises-voice-solution.component';
import { CorporateBrochureComponent } from './enterprises/corporate-brochure/corporate-brochure.component';
import { AdvantageComponent } from './enterprises/advantage/advantage.component';
import { EnterprisesTechnologyComponent } from './enterprises/enterprises-technology/enterprises-technology.component';

import { HomeuserComponent } from './homeuser/homeuser.component';
import { HomepageComponent } from './homeuser/homepage/homepage.component';
import { FeaturesComponent } from './homeuser/features/features.component';
import { FtthServicesComponent } from './homeuser/ftth-services/ftth-services.component';
import { HomeSolutionsComponent } from './homeuser/home-solutions/home-solutions.component';
import { AvailabilityComponent } from './homeuser/availability/availability.component';
import { TarrifsComponent } from './homeuser/tarrifs/tarrifs.component';
import { SpeedTestComponent } from './homeuser/speed-test/speed-test.component';


const routes: Routes = [
  //Site routes goes here 
  { 
    path: '', 
    component: SiteComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/futurehome' },
      { path:'futurehome', component:FuturehomeComponent },
      { path:'about-us', component:AboutUsComponent },
      { path:'technology', component:TechnologyComponent },
      { path:'clients', component:ClientsComponent },
      { path:'contact-us', component:ContactUsComponent },
      { path:'why-us', component:WhyUsComponent },
      { path:'terms-conditions', component:TermsConditionsComponent },
      { path:'feedback', component:FeedbackComponent },
      { path:'privacy-policy', component:PrivacyPolicyComponent },
      { path:'careers', component:CareersComponent }
    ]
},

// Enterprises routes goes here
{ 
    path: 'enterprises',
    component: EnterprisesComponent, 
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/enterprises/home' },
      { path:'home', component:HomeComponent },
      { path:'business-solutions', component:BusinessSolutionsComponent },
      { path:'internet-lease-line-services', component:InternetLeaseLineServicesComponent },
      { path:'business-broadband', component:BusinessBroadbandComponent },
      { path:'wifi-solutions', component:WifiSolutionsComponent },
      { path:'customize-solutions', component:CustomizeSolutionsComponent },
      { path:'corporate-events', component:CorporateEventsComponent },
      { path:'home-solutions', component:HomeSolutionsComponent },
      { path:'plans', component:PlansComponent },
      { path:'enterprises-voice-solution', component:EnterprisesVoiceSolutionComponent },
      { path:'corporate-brochure', component:CorporateBrochureComponent },
      { path:'enterprises-technology', component:EnterprisesTechnologyComponent },
      { path:'advantage', component:AdvantageComponent }
    ]
},

// HomeUser routes goes here
{ 
    path: 'homeuser',
    component: HomeuserComponent, 
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/homeuser/home' },
      { path:'home', component:HomepageComponent },
      { path:'features', component:FeaturesComponent },
      { path:'ftth-services', component:FtthServicesComponent },
      { path:'home-solutions', component:HomeSolutionsComponent },
      { path:'availability', component:AvailabilityComponent },
      { path:'tariffs', component:TarrifsComponent },
      { path:'speed-test', component:SpeedTestComponent }
    ]
},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
