import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { Router, NavigationStart, RoutesRecognized } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-ent-header',
  templateUrl: './ent-header.component.html',
  styleUrls: ['./ent-header.component.css']
})
export class EntHeaderComponent implements OnInit {
  
  public loading = false;
  constructor(router:Router, private _el: ElementRef) {
    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        this.loading = true;
        //console.log("fire")
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  }




  ngOnInit() {
    /*$('.dropdown').mouseenter(function(){
      $(this).addClass('open');
      $(this).children('.dropdown-toggle').attr('aria-expanded','true');
      $(this).children('.dropdown-menu').show();
      console.log('hovered')
    }).mouseleave(function(){
      $(this).removeClass('open');
      $(this).children('.dropdown-toggle').attr('aria-expanded','false');
      $(this).children('.dropdown-menu').hide();
      console.log('hover out')
    });
    */
  }
  

}
