import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-futurehome',
  templateUrl: './futurehome.component.html',
  styleUrls: ['./futurehome.component.css']
})
export class FuturehomeComponent implements OnInit {

  constructor(public title: Title, public meta: Meta) { 
    this.title.setTitle('Future Internet: Home & Enterprises Broadband Service Provider');
    this.meta.updateTag({ name: 'description', content: 'internet service provider: home user & Enterprises Broadband Service Provider'});
  }

  ngOnInit() {
  }

}
