import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprises-technology',
  templateUrl: './enterprises-technology.component.html',
  styleUrls: ['./enterprises-technology.component.css']
})
export class EnterprisesTechnologyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
