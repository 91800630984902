import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internet-lease-line-services',
  templateUrl: './internet-lease-line-services.component.html',
  styleUrls: ['./internet-lease-line-services.component.css']
})
export class InternetLeaseLineServicesComponent implements OnInit {

  constructor() { }
  

  ngOnInit() {
  }

}
