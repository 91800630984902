import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';


import { AppRoutingModule } from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { SiteComponent } from './site/site.component';
import { EnterprisesComponent } from './enterprises/enterprises.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { EntHeaderComponent } from './components/ent-header/ent-header.component';
import { EntFooterComponent } from './components/ent-footer/ent-footer.component';
import { FuturehomeComponent } from './futurehome/futurehome.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TechnologyComponent } from './technology/technology.component';
import { ClientsComponent } from './clients/clients.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { WhyUsComponent } from './why-us/why-us.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CareersComponent } from './careers/careers.component';
import { HomeComponent } from './enterprises/home/home.component';
import { BusinessSolutionsComponent } from './enterprises/business-solutions/business-solutions.component';
import { InternetLeaseLineServicesComponent } from './enterprises/internet-lease-line-services/internet-lease-line-services.component';
import { BusinessBroadbandComponent } from './enterprises/business-broadband/business-broadband.component';
import { WifiSolutionsComponent } from './enterprises/wifi-solutions/wifi-solutions.component';
import { CustomizeSolutionsComponent } from './enterprises/customize-solutions/customize-solutions.component';
import { CorporateEventsComponent } from './enterprises/corporate-events/corporate-events.component';
import { PlansComponent } from './enterprises/plans/plans.component';
import { EnterprisesVoiceSolutionComponent } from './enterprises/enterprises-voice-solution/enterprises-voice-solution.component';
import { CorporateBrochureComponent } from './enterprises/corporate-brochure/corporate-brochure.component';
import { AdvantageComponent } from './enterprises/advantage/advantage.component';
import { EnterprisesTechnologyComponent } from './enterprises/enterprises-technology/enterprises-technology.component';
import { HomeuserComponent } from './homeuser/homeuser.component';
import { HomeuserHeaderComponent } from './components/homeuser-header/homeuser-header.component';
import { HomeuserFooterComponent } from './components/homeuser-footer/homeuser-footer.component';
import { HomepageComponent } from './homeuser/homepage/homepage.component';
import { FeaturesComponent } from './homeuser/features/features.component';
import { HomeSolutionsComponent } from './homeuser/home-solutions/home-solutions.component';
import { FtthServicesComponent } from './homeuser/ftth-services/ftth-services.component';
import { AvailabilityComponent } from './homeuser/availability/availability.component';
import { TarrifsComponent } from './homeuser/tarrifs/tarrifs.component';
import { SpeedTestComponent } from './homeuser/speed-test/speed-test.component';

@NgModule({
  declarations: [
    AppComponent,
    FuturehomeComponent,
    HeaderComponent,
    FooterComponent,
    AboutUsComponent,
    EntHeaderComponent,
    EnterprisesComponent,
    EntFooterComponent,
    SiteComponent,
    TechnologyComponent,
    ClientsComponent,
    ContactUsComponent,
    WhyUsComponent,
    TermsConditionsComponent,
    FeedbackComponent,
    PrivacyPolicyComponent,
    CareersComponent,
    HomeComponent,
    BusinessSolutionsComponent,
    InternetLeaseLineServicesComponent,
    BusinessBroadbandComponent,
    WifiSolutionsComponent,
    CustomizeSolutionsComponent,
    CorporateEventsComponent,
    PlansComponent,
    EnterprisesVoiceSolutionComponent,
    CorporateBrochureComponent,
    AdvantageComponent,
    EnterprisesTechnologyComponent,
    HomeSolutionsComponent,
    HomeuserComponent,
    HomeuserHeaderComponent,
    HomeuserFooterComponent,
    HomepageComponent,
    FeaturesComponent,
    FtthServicesComponent,
    AvailabilityComponent,
    TarrifsComponent,
    SpeedTestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rotatingPlane,
      backdropBackgroundColour: 'rgba(255,255,255,0.9)', 
      //backdropBorderRadius: '4px',
      primaryColour: '#eb6e00', 
      secondaryColour: '#eb6e00', 
      tertiaryColour: '#eb6e00'
  })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
