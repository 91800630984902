import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tarrifs',
  templateUrl: './tarrifs.component.html',
  styleUrls: ['./tarrifs.component.css']
})
export class TarrifsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
