import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ftth-services',
  templateUrl: './ftth-services.component.html',
  styleUrls: ['./ftth-services.component.css']
})
export class FtthServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
