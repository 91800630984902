import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { Router, NavigationStart, RoutesRecognized } from '@angular/router';

@Component({
  selector: 'app-homeuser-header',
  templateUrl: './homeuser-header.component.html',
  styleUrls: ['./homeuser-header.component.css']
})
export class HomeuserHeaderComponent implements OnInit {

  public loading = false;
  constructor(router:Router, private _el: ElementRef) {
    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        this.loading = true;
        //console.log("fire")
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });

}
ngOnInit() {
}
}
