import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corporate-brochure',
  templateUrl: './corporate-brochure.component.html',
  styleUrls: ['./corporate-brochure.component.css']
})
export class CorporateBrochureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
